import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import {product,apiGet} from '../../services/api'

export default {
    name: "Product",
    data(){
        return{
            product_list: null,
            chk3:false,
        }
    },
    methods: {
        gotoDetail(vals){
            // this.$router.push({ name: 'product-detail' })
            if(vals.link_name){
                this.$router.push('solar') 
            }else{
                this.$router.push('product-detail/'+vals.id) 
            }
        }
    },
    async mounted () {
        document.title = "AGC - Product";
        AOS.init();
        resize();
        function resize(){
            if(window.innerWidth<800) $('.footer').css({'position':'relative','top':'0px'});
        }
        this.product_list = await apiGet(product,{
            page : 1,
            limit : 100000000,
            sort : 'ASC',
        })
        if($( window ).width()>1200){
            if((this.product_list.length/3)% 1 != 0) this.chk3 = true
        }
    },
    components: {
        Footer,Header
    }
};